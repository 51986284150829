$(document).foundation();

$(function() {
	$('.open_gallery').on('click', function(e) {
		e.preventDefault();

		$('body').find('#gallery').remove();

		var form = '<form id="gallery" action="/gallery/" method="post">';
		$.each($(this).data(), function(k,v) {
			form += '<input type="hidden" name="' + k + '" value="' + v + '">';
		});
		form += '</form>';

		$('body').append(form);
		$('#gallery').submit();
	});


	if ($('#gallery').length) {
		$("#gallery").unitegallery({
			gallery_width: 1200,
			gallery_height: 600,
			slider_scale_mode: "fit",
			theme_enable_text_panel: false,
			theme_enable_play_button: false,
			theme_enable_hidepanel_button: false,
			slider_control_zoom:false,
			slider_enable_zoom_panel: false,
		});
	}

	$('#contact_form').on('submit', function(e) {
		e.preventDefault();

		var serialize = $(this).serialize();
		$.post( "/send-mail/", serialize, function(data) {
			if(data.status) {
				$('#show_msg').removeClass('alert').addClass('success').show().find('h5').html(data.msg);
				$('#contact_form')[0].reset();
				setTimeout(function() {
					$('#show_msg').hide();
				}, 4000);
			} else {
				$('#show_msg').removeClass('success').addClass('alert').show().find('h5').html(data.msg);
			}
		}, 'json');
	});

	$('.change_lang').on('click', function() {

		var lang = $(this).data('lang');
		$.post( "/lang/", {'lang' : lang}, function(data) {
			if(data.status)
				location.reload();
		}, 'json');
	});
});
